<template>
    <modal :title="modalDetails?.title || 'Warning'" :handler="true" :cssClass="'c-modal-small-2 content-px-0'"
        :footer="false" @modalHandler="onCancel">
        <template v-slot:content>
            <div class="px-16 pb-4">
                <p class="font-16 m-0">
                    {{ modalDetails?.message || " Are you sure you want to delete?" }}
                </p>
            </div>
            <dialog-actions-bar>
                <kbutton @click="onCancel" :class="'w-100'">{{
                    modalDetails?.cancelButton || "No"
                }}</kbutton>
                <kbutton :theme-color="'primary'" @click="onConfirm" :class="'w-100 h-38'">
                    {{ modalDetails?.confirmButton || "Yes" }}</kbutton>
            </dialog-actions-bar>
        </template>
    </modal>
</template>

<script>
import { DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import modal from "@/components/common/AppModal/AppModal.vue";

export default {
    components: {
        "dialog-actions-bar": DialogActionsBar,
        kbutton: Button,
        modal,
    },
    props: {
        payload: {
            type: Object,
            required: false,
        },
        modalDetails: {
            type: Object,
            required: false,
        },
        onCancel: { type: Function },
        ids: {
            type: Array,
            default: [],
        },
        onConfirm: { type: Function },
    },
    data: function () {
        return {
            clubId: this.$route.params.id,
            isLoader: false,
        };
    },
    computed: {},
    methods: {
    },
};
</script>

<style></style>
