<template src="./memberDetails.html"></template>
<style lang="scss">
@import "./memberDetails.scss";
</style>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import moment from "moment";
import Breadcrumb from "../../../common/AppBreadCrumbs/BreadCrumbs.vue";
import memberDetailsList from "./memberDetailsList/memberDetailsList";
import memberDetailsSegment from "./memberDetailsSegment/memberDetailsSegment";
import memberDetailsActivityFeeds from "./memberDetailsActivityFeeds/memberDetailsActivityFeeds.vue";
import memberDetailsEdit from "./memberDetailsEdit/memberDetailsEdit.vue";
import {
    getAudienceProfileService,
} from "@/services/clubDetails/profileDetails.service";
import AppLoader from "./../../../common/AppLoader/AppLoader.vue";
import addToListModal from "./addToListModal/addToListModal.vue";
export default {
    components: {
        kbutton: Button,
        Breadcrumb,
        memberDetailsList,
        memberDetailsSegment,
        memberDetailsActivityFeeds,
        memberDetailsEdit,
        AppLoader,
        addToListModal
    },
    data() {
        return {
            routeName: this.$route.name,
            isShowAddtoList: false,
            isMemberListReload: false,
            isLoading: false,
            isDrawerOpen: false,
            memberDetails: {},
            clubId: this.$route.params.id,
            userId: this.$route.params.memberId,
            subscriberStatus: {
                0: "Non-subscribed",
                1: "Subscribed",
                2: "Unsubscribed",
                3: "Cleaned",
            },
            moduleCode: "members",
            itemCode: null,
            itemTagTypes: {
                1: "dpAudience",
                2: "ecAudience",
                3: "vAudience",
            },
            moduleTagTypes: {
                1: "digitalPass",
                2: "emailCampaign",
                3: "voucher",
            },
            childItemCodeType: {
                1: "dpAllAudience",
                2: "ecAllAudience",
                3: "vAllAudience",
            },
            securityPayLoad: {},
          type: +this.$route.query.type,
          audienceId: this.$route.query.audienceId
        };
    },
    computed: {
    },

    watch: {

    },

    mounted() {
      let Type = this.type
      let AudienceId = this.audienceId
        if (this.hasGetAccess())
          if (Type) {
            this.getAudienceProfile({
              ClubId: this.clubId,
              ClubUserId: this.userId,
              IsAudience: true,
              AudienceId: AudienceId,
              EntityType: Type
            });
          } else {
            this.getAudienceProfile({
              ClubId: this.clubId,
              ClubUserId: this.userId,
            });
          }
    },

    methods: {
        dateFormate(date) {
            return moment(date).format("DD-MMM-YYYY");
        },
        hasGetAccess() {
            return this.$hasAccessToModule({
                permissionKey: "hasGetPermission",
                moduleCode: this.routeName != 'MemberDetails' ? this.moduleTagTypes[+this.$route.query.type] : this.moduleCode,
                itemCode: this.routeName != 'MemberDetails' ? this.itemTagTypes[+this.$route.query.type] : this.itemCode,
                childItemCode: this.routeName != 'MemberDetails' ? this.childItemCodeType[+this.$route.query.type] : null,
            });
        },
        hasDeleteAccess() {
            return this.$hasAccessToModule({
                permissionKey: "hasDeletePermission",
                moduleCode: this.routeName != 'MemberDetails' ? this.moduleTagTypes[+this.$route.query.type] : this.moduleCode,
                itemCode: this.routeName != 'MemberDetails' ? this.itemTagTypes[+this.$route.query.type] : this.itemCode,
                childItemCode: this.routeName != 'MemberDetails' ? this.childItemCodeType[+this.$route.query.type] : null,
            });
        },
        hasEditAccess() {
            return this.$hasAccessToModule({
                permissionKey: "hasPutPermission",
                moduleCode: this.routeName != 'MemberDetails' ? this.moduleTagTypes[+this.$route.query.type] : this.moduleCode,
                itemCode: this.routeName != 'MemberDetails' ? this.itemTagTypes[+this.$route.query.type] : this.itemCode,
                childItemCode: this.routeName != 'MemberDetails' ? this.childItemCodeType[+this.$route.query.type] : null,
            });
        },
        hasCreateAccess() {
            return this.$hasAccessToModule({
                permissionKey: "hasPostPermission",
                moduleCode: this.routeName != 'MemberDetails' ? this.moduleTagTypes[+this.$route.query.type] : this.moduleCode,
                itemCode: this.routeName != 'MemberDetails' ? this.itemTagTypes[+this.$route.query.type] : this.itemCode,
                childItemCode: this.routeName != 'MemberDetails' ? this.childItemCodeType[+this.$route.query.type] : null,
            });
        },
        onClickAddToList() {
            this.isShowAddtoList = true;
        },
        closeAddToListModal() {
            this.isShowAddtoList = false;
            this.isLoading = false;
        },
        toggleDrawer() {
            this.isDrawerOpen = !this.isDrawerOpen;
        },
        closeDrawer() {
            this.isDrawerOpen = false;
        },
        updateCloseDrawer() {
            this.isDrawerOpen = false;
          if (this.type) {
            this.getAudienceProfile({
              ClubId: this.clubId,
              ClubUserId: this.userId,
              IsAudience: true,
              AudienceId: this.audienceId,
              EntityType: this.type
            });
          } else {
            this.getAudienceProfile({
              ClubId: this.clubId,
              ClubUserId: this.userId,
            });
          }
        },
        getDateLocal(date) {
            if (date != null) {
                return moment(date).format("DD-MMM-YYYY");
            } else {
                return "-";
            }
        },
        averageOrderValue(totalConversionValue, totalConversion) {
            if (totalConversionValue && totalConversion > 0) {
                return (totalConversionValue / totalConversion).toFixed(2);
            }
            return '0.00';
        },
        conversionRate(totalConversion, uniqueDelivered) {
            if (uniqueDelivered && totalConversion > 0) {
                return ((totalConversion / uniqueDelivered) * 100).toFixed(2);
            }
            return '0.00';
        },
        getAudienceProfile(payload) {
            this.isLoading = true;
            getAudienceProfileService(payload)
                .then((response) => {
                    this.isLoading = false;
                    const data = response?.data?.data;
                    this.memberDetails = data;
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.error("Error fetching tenement list:", error);
                });
        },
    },
};
</script>
