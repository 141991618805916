<template src="./activityDetails.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "@/components/common/AppModal/AppModal";
import { DropDownList } from '@progress/kendo-vue-dropdowns';
import { DropDownTree } from "@progress/kendo-dropdowntree-vue-wrapper";
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import {
    getActivityDetailsService,
} from "@/services/clubDetails/profileDetails.service";

export default {
    components: {
        "k-dialog": Dialog,
        "dialog-actions-bar": DialogActionsBar,
        kbutton: Button,
        "k-input": Input,
        modal,
        'dropdownlist': DropDownList,
        dropdowntree: DropDownTree,
        "vue-json-pretty": VueJsonPretty
    },
    props: {
        activityDetailsDataItem: {
            type: Object,
            default: {},
        },
    },
    computed: {},
    data: function () {
        return {
            clubId: this.$route.params.id,
            clubUserId: this.$route.params.memberId,
            isLoading: false,
            detailsData:{}
        };
    },
    mounted() {
        this.getActivityDetails({
            clubId: this.clubId,
            id:this.activityDetailsDataItem?.id,
            activityType: this.activityDetailsDataItem?.activityType
        });
    },
    methods: {
        modalHandler() {
            this.$emit("closeActivityDetailsModal");
        },
        getActivityDetails(payload) {
            this.isLoading = true;
            getActivityDetailsService(payload)
                .then((response) => {
                    this.isLoading = false;
                    const detailsData = response?.data?.data;
                    this.detailsData = detailsData;
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.error("Error fetching tenement list:", error);
                });
        },
    },
};
</script>
