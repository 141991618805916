<template src="./memberDetailsList.html"></template>
<style lang="scss">
@import "./memberDetailsList.scss";
</style>

<script>
import { Grid } from "@progress/kendo-vue-grid";
import {
  getAudienceListsService,
  deleteAudienceListService,
} from "@/services/clubDetails/profileDetails.service";
import { Button } from "@progress/kendo-vue-buttons";
import moment from "moment";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import addToListModal from "../addToListModal/addToListModal.vue";
import appConfirmationModal from "@/components/common/AppConfirmationModal/AppConfirmationModal";
import { DropDownList } from "@progress/kendo-vue-dropdowns";

export default {
  components: {
    dropdownlist: DropDownList,
    Grid,
    kbutton: Button,
    AppLoader,
    addToListModal,
    appConfirmationModal,
  },
  data() {
    return {
      clubId: this.$route.params.id,
      userId: this.$route.params.memberId,
      memberList: [],
      isLoading: false,
      isShowAddtoList: false,
      selectedEntity: { text: "All", id: 0 },
      entityList: [
        { text: "All", id: 0 },
        { text: "Digital Pass", id: 1 },
        { text: "Email Campaign", id: 2 },
        { text: "Voucher", id: 3 },
      ],
      routeName: this.$route.name,
      moduleCode: "members",
      itemCode: null,
      itemTagTypes: {
        1: "dpAudience",
        2: "ecAudience",
        3: "vAudience",
      },
      moduleTagTypes: {
        1: "digitalPass",
        2: "emailCampaign",
        3: "voucher",
      },
      childItemCodeType: {
        1: "dpAllAudience",
        2: "ecAllAudience",
        3: "vAllAudience",
      },
      deleteModalDetails: {
        title: "Remove from List",
        message: "Are you sure you want to remove this audience from the list?",
        confirmButton: "Remove",
        cancelButton: "Cancel",
      },
      rowObj: {},
      deleteModalVisible: false,
    };
  },
  computed: {
    columns() {
      return [
        {
          field: "source",
          title: "Source",
        },
        {
          field: "name",
          title: "List Name",
          cell: "nameCell",
        },
        {
          field: "updatedAt",
          title: "Date",
          cell: "dateCell",
        },
        {
          field: "actions",
          title: " ",
          cell: "actions",
          width: "60px",
        },
      ];
    },
  },

  watch: {},
  mounted() {
    if (this.hasCreateAccess()) {
      this.refetchGridData();
    }
  },

  methods: {
    hasGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode:
          this.routeName != "MemberDetails"
            ? this.moduleTagTypes[+this.$route.query.type]
            : this.moduleCode,
        itemCode:
          this.routeName != "MemberDetails"
            ? this.itemTagTypes[+this.$route.query.type]
            : this.itemCode,
        childItemCode:
          this.routeName != "MemberDetails"
            ? this.childItemCodeType[+this.$route.query.type]
            : null,
      });
    },
    hasDeleteAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode:
          this.routeName != "MemberDetails"
            ? this.moduleTagTypes[+this.$route.query.type]
            : this.moduleCode,
        itemCode:
          this.routeName != "MemberDetails"
            ? this.itemTagTypes[+this.$route.query.type]
            : this.itemCode,
        childItemCode:
          this.routeName != "MemberDetails"
            ? this.childItemCodeType[+this.$route.query.type]
            : null,
      });
    },
    hasEditAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode:
          this.routeName != "MemberDetails"
            ? this.moduleTagTypes[+this.$route.query.type]
            : this.moduleCode,
        itemCode:
          this.routeName != "MemberDetails"
            ? this.itemTagTypes[+this.$route.query.type]
            : this.itemCode,
        childItemCode:
          this.routeName != "MemberDetails"
            ? this.childItemCodeType[+this.$route.query.type]
            : null,
      });
    },

    hasCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode:
          this.routeName != "MemberDetails"
            ? this.moduleTagTypes[+this.$route.query.type]
            : this.moduleCode,
        itemCode:
          this.routeName != "MemberDetails"
            ? this.itemTagTypes[+this.$route.query.type]
            : this.itemCode,
        childItemCode:
          this.routeName != "MemberDetails"
            ? this.childItemCodeType[+this.$route.query.type]
            : null,
      });
    },
    getAudienceLists(payload) {
      this.isLoading = true;
      getAudienceListsService(payload)
        .then((response) => {
          this.isLoading = false;
          const list = response?.data?.data;
          this.memberList = list;
        })
        .catch((error) => {
          this.isLoading = false;
          console.error("Error fetching tenement list:", error);
        });
    },
    deleteAudienceList(payload) {
      this.onCancelDeleteModal();
      this.isLoading = true;
      deleteAudienceListService(payload)
        .then((response) => {
          this.isLoading = false;
          this.refetchGridData();
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "Successfully removed from the list!",
            type: "success",
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "Something went wrong. Please try again later.",
            type: "error",
          });
        });
    },
    getDateLocal(date) {
      if (date != null) {
        return moment(date).format("DD-MMM-YYYY");
      } else {
        return "-";
      }
    },
    onClickAddToList() {
      this.isShowAddtoList = true;
    },
    deleteList(data) {
      this.rowObj = data;
      this.deleteModalVisible = true;
    },
    onConfirm() {
      this.deleteAudienceList({
        clubId: this.clubId,
        clubUserId: this.userId,
        listId: this.rowObj.listId,
      });
    },
    onCancelDeleteModal() {
      this.rowObj = {};
      this.deleteModalVisible = false;
    },

    closeAddToListModal() {
      this.isShowAddtoList = false;
    },
    reFetchData() {
      this.isShowAddtoList = false;
      this.refetchGridData();
    },
    refetchGridData() {
      this.getAudienceLists({
        ClubId: this.clubId,
        ClubUserId: this.userId,
        entityType: this.selectedEntity?.id,
      });
    },

    handleValueChange(value, field) {
      this[field] = value;
      this.refetchGridData();
    },
  },
};
</script>
