<template src="./memberDetailsSegment.html"></template>
<style lang="scss">
@import "./memberDetailsSegment.scss";
</style>

<script>
import { Grid } from "@progress/kendo-vue-grid";
import moment from "moment";
import {
    getAudienceSegmentsService,
} from "@/services/clubDetails/profileDetails.service";
export default {
    components: {
        Grid
    },
    data() {
        return {
            clubId: this.$route.params.id,
            userId: this.$route.params.memberId,
            segmentList: [
            ],
            isLoading: false
        };
    },
    computed: {
        columns() {
            return [
                {
                    field: "name",
                    title: "Segment Name",
                    cell: "nameCell",
                },
                {
                    field: "updatedAt",
                    title: "Date",
                    cell: "dateCell",
                },
            ];
        },
    },

    watch: {

    },
    mounted() {
        this.getAudienceSegments({
            ClubId: this.clubId,
            ClubUserId: this.userId
        })
    },

    methods: {

        getAudienceSegments(payload) {
            this.isLoading = true;
            getAudienceSegmentsService(payload)
                .then((response) => {
                    this.isLoading = false;
                    const list = response?.data?.data;
                    this.segmentList = list;
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.error("Error fetching tenement list:", error);
                });
        },
        getDateLocal(date) {
            if (date != null) {
                return moment(date).format("DD-MMM-YYYY");
            } else {
                return "-";
            }
        },
    },
};
</script>
