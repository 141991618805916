<app-loader v-if="this.isLoading"></app-loader>
<div>
  <Breadcrumb :id="$route.params.id" />
  <div
    v-if="!isLoading"
    class="min-height-calc-100-182 max-height-100--60 overflow-y-auto"
  >
    <div
      class="dashboard-header p-0 box-shadow-none bg-white border-bottom-normal-1"
    >
      <div class="px-20 px-10-sm">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center w-100-vsm pt-12 pb-16 w-100"
        >
          <div class="d-flex flex-wrap align-items-center gap-2">
            <h2 class="font-18 font-w-600 mb-2 mb-sm-0">
              {{memberDetails?.audienceBasicDetails?.normalizedName}}
            </h2>
            <span
              v-if="memberDetails?.audienceBasicDetails?.subscriberStatus==0"
              class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8"
              >Non-subscribed</span
            >
            <span
              v-if="memberDetails?.audienceBasicDetails?.subscriberStatus==1"
              class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8 live"
              >Subscribed</span
            >
            <span
              v-if="memberDetails?.audienceBasicDetails?.subscriberStatus==2"
              class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8 draft"
              >Unsubscribed</span
            >
            <span
              v-if="memberDetails?.audienceBasicDetails?.subscriberStatus==3"
              class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8 paused"
              >Cleaned</span
            >
          </div>
          <div v-if="hasEditAccess()" class="d-flex">
            <kbutton
              @click="toggleDrawer"
              :fill-mode="'outline'"
              :class="'h-38 font-w-500 border-normal-1 base-color bg-white hvr-bg-primary hvr-icon-white'"
              :theme-color="'primary'"
            >
              <span>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_14599_48599)">
                    <path
                      d="M7.33301 2.66665H2.66634C2.31272 2.66665 1.97358 2.80713 1.72353 3.05718C1.47348 3.30723 1.33301 3.64637 1.33301 3.99999V13.3333C1.33301 13.6869 1.47348 14.0261 1.72353 14.2761C1.97358 14.5262 2.31272 14.6667 2.66634 14.6667H11.9997C12.3533 14.6667 12.6924 14.5262 12.9425 14.2761C13.1925 14.0261 13.333 13.6869 13.333 13.3333V8.66666M12.333 1.66665C12.5982 1.40144 12.9579 1.25244 13.333 1.25244C13.7081 1.25244 14.0678 1.40144 14.333 1.66665C14.5982 1.93187 14.7472 2.29158 14.7472 2.66665C14.7472 3.04173 14.5982 3.40144 14.333 3.66665L7.99967 9.99999L5.33301 10.6667L5.99967 7.99999L12.333 1.66665Z"
                      stroke="#404A61"
                      stroke-width="1.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_14599_48599">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              Edit
            </kbutton>
            <memberDetailsEdit
              v-if="isDrawerOpen"
              :isDrawerOpen="isDrawerOpen"
              @closeDrawer="closeDrawer"
              :type="type"
              :audienceId="audienceId"
              @updateCloseDrawer="updateCloseDrawer"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="px-15 py-20 w-1430 mx-auto">
      <div class="row">
        <div class="col-xxl-7 col-xl-12">
          <div class="d-flex flex-wrap justify-content-between gap-3 mb-16">
            <div
              class="flex-fill d-flex justify-content-between align-items-center bg-white py-9 px-10 radius-10"
            >
              <div class="pe-1">
                <p class="font-13 l-height-17 font-w-500 mb-1">Opened</p>
                <h6 class="font-14 l-height-24 font-w-600 m-0">
                  {{memberDetails?.audienceSummary?.totalOpened||0}}
                </h6>
              </div>
              <div class="ps-1">
                <svg
                  width="27"
                  height="26"
                  viewBox="0 0 27 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.65039 6.77083C3.65039 4.82633 5.22672 3.25 7.17122 3.25H19.6296C21.5741 3.25 23.1504 4.82633 23.1504 6.77083V19.2292C23.1504 21.1737 21.5741 22.75 19.6296 22.75H7.17122C5.22672 22.75 3.65039 21.1737 3.65039 19.2292V6.77083ZM7.17122 4.875C6.12418 4.875 5.27539 5.72379 5.27539 6.77083V19.2292C5.27539 20.2762 6.12418 21.125 7.17122 21.125H10.1504V4.875H7.17122ZM11.7754 4.875V15.1667H21.5254V6.77083C21.5254 5.72379 20.6766 4.875 19.6296 4.875H11.7754ZM21.5254 16.7917H11.7754V21.125H19.6296C20.6766 21.125 21.5254 20.2762 21.5254 19.2292V16.7917Z"
                    fill="#20C997"
                  />
                </svg>
              </div>
            </div>
            <div
              class="flex-fill d-flex justify-content-between align-items-center bg-white py-9 px-10 radius-10"
            >
              <div class="pe-1">
                <p class="font-13 l-height-17 font-w-500 mb-1">Email Sent</p>
                <h6 class="font-14 l-height-24 font-w-600 m-0">
                  {{memberDetails?.audienceSummary?.totalSent||0}}
                </h6>
              </div>
              <div class="ps-1">
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.2993 3.99907C11.1015 4.47324 10.9582 4.97586 10.8774 5.49909L7.05078 5.5C6.13261 5.5 5.37959 6.20711 5.30658 7.10647L5.30078 7.25V13.9991L9.80078 14C10.1805 14 10.4943 14.2822 10.5439 14.6482L10.5508 14.75C10.5508 15.9926 11.5581 17 12.8008 17C13.9916 17 14.9664 16.0748 15.0456 14.904L15.0508 14.75C15.0508 14.3703 15.3329 14.0565 15.699 14.0068L15.8008 14L20.3008 13.9991L20.3019 12.2672C20.8528 11.98 21.3574 11.616 21.8016 11.1896L21.8008 18.75C21.8008 20.483 20.4443 21.8992 18.7352 21.9949L18.5508 22H7.05078C5.31775 22 3.90154 20.6435 3.80593 18.9344L3.80078 18.75V7.25C3.80078 5.51697 5.15724 4.10075 6.86636 4.00514L7.05078 4L11.2993 3.99907ZM20.3008 15.4991L16.4758 15.5C16.1412 17.1483 14.7255 18.404 13.0011 18.4947L12.8008 18.5C10.9866 18.5 9.47325 17.2117 9.12579 15.5L5.30078 15.4991V18.75C5.30078 19.6682 6.00789 20.4212 6.90725 20.4942L7.05078 20.5H18.5508C19.469 20.5 20.222 19.7929 20.295 18.8935L20.3008 18.75V15.4991ZM17.3008 1C20.3383 1 22.8008 3.46243 22.8008 6.5C22.8008 9.53757 20.3383 12 17.3008 12C14.2632 12 11.8008 9.53757 11.8008 6.5C11.8008 3.46243 14.2632 1 17.3008 1ZM17.5165 3.58859L17.4472 3.64645L17.3894 3.71569C17.2713 3.8862 17.2713 4.1138 17.3894 4.28431L17.4472 4.35355L19.0928 6H13.8008L13.7109 6.00806C13.5068 6.0451 13.3459 6.20603 13.3088 6.41012L13.3008 6.5L13.3088 6.58988C13.3459 6.79397 13.5068 6.9549 13.7109 6.99194L13.8008 7H19.0928L17.4472 8.64645L17.3894 8.71569C17.2544 8.91056 17.2737 9.17999 17.4472 9.35355C17.6208 9.52712 17.8902 9.5464 18.0851 9.41141L18.1543 9.35355L20.6958 6.806L20.7289 6.75845L20.763 6.69104L20.7842 6.62815L20.7986 6.54629L20.8008 6.5L20.798 6.4466L20.7842 6.37186L20.754 6.2886L20.7119 6.21534L20.6663 6.15887L18.1543 3.64645L18.0851 3.58859C17.9146 3.47047 17.687 3.47047 17.5165 3.58859Z"
                    fill="#6F42C1"
                  />
                </svg>
              </div>
            </div>
            <div
              class="flex-fill d-flex justify-content-between align-items-center bg-white py-9 px-10 radius-10"
            >
              <div class="pe-1">
                <p class="font-13 l-height-17 font-w-500 mb-1">Delivered</p>
                <h6 class="font-14 l-height-24 font-w-600 m-0">
                  {{memberDetails?.audienceSummary?.totalDelivered||0}}
                </h6>
              </div>
              <div class="ps-1">
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.86589 4.3335C5.39313 4.3335 4.19922 5.5274 4.19922 7.00016V20.3335C4.19922 21.8063 5.39313 23.0002 6.86589 23.0002C8.33864 23.0002 9.53255 21.8063 9.53255 20.3335V7.00016C9.53255 5.5274 8.33864 4.3335 6.86589 4.3335ZM5.53255 7.00016C5.53255 6.26378 6.12951 5.66683 6.86589 5.66683C7.60227 5.66683 8.19922 6.26378 8.19922 7.00016V20.3335C8.19922 21.0699 7.60227 21.6668 6.86589 21.6668C6.12951 21.6668 5.53255 21.0699 5.53255 20.3335V7.00016ZM10.8659 11.0002C10.8659 9.5274 12.0598 8.3335 13.5326 8.3335C15.0053 8.3335 16.1992 9.5274 16.1992 11.0002V20.3335C16.1992 21.8063 15.0053 23.0002 13.5326 23.0002C12.0598 23.0002 10.8659 21.8063 10.8659 20.3335V11.0002ZM13.5326 9.66683C12.7962 9.66683 12.1992 10.2638 12.1992 11.0002V20.3335C12.1992 21.0699 12.7962 21.6668 13.5326 21.6668C14.2689 21.6668 14.8659 21.0699 14.8659 20.3335V11.0002C14.8659 10.2638 14.2689 9.66683 13.5326 9.66683ZM17.5326 15.0002C17.5326 13.5274 18.7265 12.3335 20.1992 12.3335C21.672 12.3335 22.8659 13.5274 22.8659 15.0002V20.3335C22.8659 21.8063 21.672 23.0002 20.1992 23.0002C18.7265 23.0002 17.5326 21.8063 17.5326 20.3335V15.0002ZM20.1992 13.6668C19.4628 13.6668 18.8659 14.2638 18.8659 15.0002V20.3335C18.8659 21.0699 19.4628 21.6668 20.1992 21.6668C20.9356 21.6668 21.5326 21.0699 21.5326 20.3335V15.0002C21.5326 14.2638 20.9356 13.6668 20.1992 13.6668Z"
                    fill="#2990FF"
                  />
                </svg>
              </div>
            </div>
            <div
              class="flex-fill d-flex justify-content-between align-items-center bg-white py-9 px-10 radius-10"
            >
              <div class="pe-1">
                <p class="font-13 l-height-17 font-w-500 mb-1">Clicked</p>
                <h6 class="font-14 l-height-24 font-w-600 m-0">
                  {{memberDetails?.audienceSummary?.totalClicked||0}}
                </h6>
              </div>
              <div class="ps-1">
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.26563 4.41253C7.26563 3.28576 8.57662 2.667 9.44647 3.38321L24.1213 15.4661C25.0871 16.2613 24.5248 17.8288 23.2738 17.8288H15.8179C15.2373 17.8288 14.6854 18.0811 14.3055 18.5201L9.60725 23.95C8.79929 24.8838 7.26562 24.3124 7.26563 23.0776L7.26563 4.41253ZM23.2738 16.4954L8.59896 4.41253L8.59896 23.0776L13.2972 17.6477C13.9304 16.9159 14.8503 16.4954 15.8179 16.4954H23.2738Z"
                    fill="#0DCAF0"
                  />
                </svg>
              </div>
            </div>
            <div
              class="flex-fill d-flex justify-content-between align-items-center bg-white py-9 px-10 radius-10"
            >
              <div class="pe-1">
                <p class="font-13 l-height-17 font-w-500 mb-1">Bounced</p>
                <h6 class="font-14 l-height-24 font-w-600 m-0">
                  {{memberDetails?.audienceSummary?.totalBounced||0}}
                </h6>
              </div>
              <div class="ps-1">
                <svg
                  width="27"
                  height="28"
                  viewBox="0 0 27 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.9987 22.6667C22.9987 23.0349 22.7002 23.3334 22.332 23.3334L6.33203 23.3334C5.22746 23.3334 4.33203 22.438 4.33203 21.3334V5.33341C4.33203 4.96522 4.63051 4.66675 4.9987 4.66675C5.36689 4.66675 5.66536 4.96522 5.66536 5.33341L5.66536 21.3334C5.66536 21.7016 5.96384 22.0001 6.33203 22.0001L22.332 22.0001C22.7002 22.0001 22.9987 22.2986 22.9987 22.6667ZM16.332 8.00008C16.332 7.63189 16.6305 7.33342 16.9987 7.33342H22.332C22.7002 7.33342 22.9987 7.63189 22.9987 8.00008V13.3334C22.9987 13.7016 22.7002 14.0001 22.332 14.0001C21.9638 14.0001 21.6654 13.7016 21.6654 13.3334V9.60952L15.4701 15.8048C15.345 15.9298 15.1755 16.0001 14.9987 16.0001C14.8219 16.0001 14.6523 15.9298 14.5273 15.8048L12.332 13.6096L8.13677 17.8048C7.87643 18.0652 7.45432 18.0652 7.19397 17.8048C6.93362 17.5445 6.93362 17.1224 7.19397 16.862L11.8606 12.1953C11.9857 12.0703 12.1552 12.0001 12.332 12.0001C12.5089 12.0001 12.6784 12.0703 12.8034 12.1953L14.9987 14.3906L20.7225 8.66675H16.9987C16.6305 8.66675 16.332 8.36827 16.332 8.00008Z"
                    fill="#DC3545"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div
            class="d-flex flex-wrap flex-sm-nowrap py-20 radius-10 bg-white box-shadow-5 mb-16"
          >
            <div class="flex-fill px-20 w-100-vsm">
              <h6 class="font-12 l-height-24 font-w-600 m-0">Total Orders</h6>
              <p class="font-14 l-height-24 font-w-400 m-0">
                {{memberDetails?.audienceSummary?.totalConversion||0}}
              </p>
            </div>
            <div
              class="flex-fill px-20 w-100-vsm border-left-normal-1 border-left-0-vsm"
            >
              <h6 class="font-12 l-height-24 font-w-600 m-0">
                Total Order Value
              </h6>
              <p class="font-14 l-height-24 font-w-400 m-0">
                ${{memberDetails?.audienceSummary?.totalConversionValue || 0}}
              </p>
            </div>
            <div
              class="flex-fill px-20 w-100-vsm border-left-normal-1 border-left-0-vsm"
            >
              <h6 class="font-12 l-height-24 font-w-600 m-0">
                Average Order Value
              </h6>
              <p class="font-14 l-height-24 font-w-400 m-0">
                ${{averageOrderValue(this.memberDetails?.audienceSummary?.totalConversionValue,
                this.memberDetails?.audienceSummary?.totalConversion)}}
              </p>
            </div>
            <div
              class="flex-fill px-20 w-100-vsm border-left-normal-1 border-left-0-vsm"
            >
              <h6 class="font-12 l-height-24 font-w-600 m-0">
                Number of Conversions
              </h6>
              <p class="font-14 l-height-24 font-w-400 m-0">
                {{this.memberDetails?.audienceSummary?.totalConversion}}
              </p>
            </div>
          </div>
          <div class="pt-20 pb-8 radius-10 bg-white box-shadow-5 mb-16">
            <div class="px-20">
              <h4 class="font-18 font-w-600 mb-10 l-height-32">
                Basic Details
              </h4>
              <div class="row">
                <div class="col-sm-6 col-12">
                  <div class="mb-12">
                    <h6 class="font-12 font-w-600 m-0 l-height-24 base-color">
                      First Name
                    </h6>
                    <p class="font-13 font-w-500 m-0 l-height-24 text-color">
                      {{memberDetails?.audienceBasicDetails?.firstName||"-"}}
                    </p>
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="mb-12">
                    <h6 class="font-12 font-w-600 m-0 l-height-24 base-color">
                      Last Name
                    </h6>
                    <p class="font-13 font-w-500 m-0 l-height-24 text-color">
                      {{memberDetails?.audienceBasicDetails?.lastName||"-"}}
                    </p>
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="mb-12">
                    <h6 class="font-12 font-w-600 m-0 l-height-24 base-color">
                      Email
                    </h6>
                    <p class="font-13 font-w-500 m-0 l-height-24 text-color">
                      {{memberDetails?.audienceBasicDetails?.email||"-"}}
                    </p>
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="mb-12">
                    <h6 class="font-12 font-w-600 m-0 l-height-24 base-color">
                      Phone Number
                    </h6>
                    <p class="font-13 font-w-500 m-0 l-height-24 text-color">
                      {{memberDetails?.audienceBasicDetails?.mobile||"-"}}
                    </p>
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="mb-12">
                    <h6 class="font-12 font-w-600 m-0 l-height-24 base-color">
                      Gender
                    </h6>
                    <p class="font-13 font-w-500 m-0 l-height-24 text-color">
                      {{memberDetails?.audienceBasicDetails?.genderName||"-"}}
                    </p>
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="mb-12">
                    <h6 class="font-12 font-w-600 m-0 l-height-24 base-color">
                      Timezone
                    </h6>
                    <p class="font-13 font-w-500 m-0 l-height-24 text-color">
                      {{memberDetails?.audienceBasicDetails?.timezone||"-"}}
                    </p>
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="mb-12">
                    <h6 class="font-12 font-w-600 m-0 l-height-24 base-color">
                      Join Date
                    </h6>
                    <p class="font-13 font-w-500 m-0 l-height-24 text-color">
                      {{this.getDateLocal(memberDetails?.audienceBasicDetails?.joiningDate)
                      ||"-"}}
                    </p>
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="mb-12">
                    <h6 class="font-12 font-w-600 m-0 l-height-24 base-color">
                      Update Date
                    </h6>
                    <p class="font-13 font-w-500 m-0 l-height-24 text-color">
                      {{this.getDateLocal(memberDetails?.audienceBasicDetails?.updatedAt)
                      ||"-"}}
                    </p>
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="mb-12">
                    <h6 class="font-12 font-w-600 m-0 l-height-24 base-color">
                      First Active Date
                    </h6>
                    <p class="font-13 font-w-500 m-0 l-height-24 text-color">
                      {{this.getDateLocal(memberDetails?.audienceBasicDetails?.firstActive)
                      ||"-"}}
                    </p>
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="mb-12">
                    <h6 class="font-12 font-w-600 m-0 l-height-24 base-color">
                      Last Active Date
                    </h6>
                    <p class="font-13 font-w-500 m-0 l-height-24 text-color">
                      {{this.getDateLocal(memberDetails?.audienceBasicDetails?.lastActive)
                      ||"-"}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="memberDetails?.audienceCustomFieldDetails?.length>0"
            class="px-20 pt-20 pb-10 radius-10 bg-white box-shadow-5 mb-16"
          >
            <h4 class="font-18 font-w-600 mb-10">Custom Fields</h4>

            <div
              v-for="item in memberDetails?.audienceCustomFieldDetails"
              :key="item.id"
              class="row"
            >
              <p
                v-if="[1,3].includes(item.fieldType) && item.isVisible"
                class="font-12 font-w-500 mb-10"
              >
                <span class="font-12" l-height-24 font-w-600 base-color
                  >{{item?.label}}:</span
                >
                {{item?.value}}
              </p>
              <p
                v-if="[2].includes(item.fieldType) && item.isVisible"
                class="font-12 font-w-500 mb-10"
              >
                <span class="font-12" l-height-24 font-w-600 base-color
                  >{{item?.label}}:</span
                >
                {{item?.value}}
              </p>
              <p
                v-if="[4].includes(item.fieldType) &&  item.isVisible"
                class="font-12 font-w-500 mb-10"
              >
                <span class="font-w-600">{{item?.label}}:</span>
                {{dateFormate(item?.value)}}
              </p>
            </div>
          </div>
          <div
            class="px-20 pt-20 pb-10 radius-10 bg-white box-shadow-5 mb-16 position-relative"
          >
            <!-- <div v-if="hasCreateAccess()" class="d-flex justify-content-between align-items-center mb-10">
                            <h4 class="font-18 font-w-600 mb-0">Lists</h4>
                            <kbutton @click="onClickAddToList" :fill-mode="'outline'"
                            :class="'w-100-vsm h-38 font-w-500 border-normal-1 base-color bg-white hvr-bg-primary'" :theme-color="'primary'">
                            Add to List</kbutton>
                        </div>
                        <addToListModal v-if="isShowAddtoList" @closeAddToListModal="closeAddToListModal" /> -->
            <memberDetailsList v-if="!isMemberListReload" />
          </div>
          <div class="px-20 pt-20 pb-10 radius-10 bg-white box-shadow-5 mb-16">
            <h4 class="font-18 font-w-600 mb-0 h-38 font-w-500">Segments</h4>
            <memberDetailsSegment />
          </div>
          <div class="px-20 pt-20 pb-10 radius-10 bg-white box-shadow-5 mb-16">
            <h4 class="font-18 font-w-600 mb-10">Subscription Details</h4>
            <p class="font-12 font-w-500 mb-10">
              <span class="font-12 l-height-24 font-w-600 base-color me-2"
                >Subscription Status:</span
              >
              <!-- <span
                class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8 live"
                >{{subscriberStatus[memberDetails?.audienceSubscriptonDetails?.subscriberStatus]
                || ""}}</span
              > -->

              <span
              v-if="memberDetails?.audienceBasicDetails?.subscriberStatus==0"
              class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8"
              >{{subscriberStatus[memberDetails?.audienceSubscriptonDetails?.subscriberStatus]
                || ""}}</span
            >
            <span
              v-if="memberDetails?.audienceBasicDetails?.subscriberStatus==1"
              class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8 live"
              >{{subscriberStatus[memberDetails?.audienceSubscriptonDetails?.subscriberStatus]
                || ""}}</span
            >
            <span
              v-if="memberDetails?.audienceBasicDetails?.subscriberStatus==2"
              class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8 draft"
              >{{subscriberStatus[memberDetails?.audienceSubscriptonDetails?.subscriberStatus]
                || ""}}</span
            >
            <span
              v-if="memberDetails?.audienceBasicDetails?.subscriberStatus==3"
              class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8 paused"
              >{{subscriberStatus[memberDetails?.audienceSubscriptonDetails?.subscriberStatus]
                || ""}}</span
            >
            </p>
            <p class="font-12 font-w-500 mb-10">
              <span class="font-12 l-height-24 font-w-600 base-color me-2"
                >Consent Date:</span
              >
              {{memberDetails?.audienceSubscriptonDetails?.consentDate || ""}}
            </p>
            <p class="font-12 font-w-500 mb-10">
              <span class="font-12 l-height-24 font-w-600 base-color me-2"
                >Opt-In Source:
              </span>
              {{memberDetails?.audienceSubscriptonDetails?.optInSource || ""}}
            </p>
          </div>
          <div class="px-20 pt-20 pb-10 radius-10 bg-white box-shadow-5 mb-16">
            <h4 class="font-18 font-w-600 mb-10">Source</h4>
            <p class="font-12 font-w-500 mb-10">
              <span class="font-12 l-height-24 font-w-600 base-color me-2"
                >Primary Source:</span
              >
              {{memberDetails?.audienceSource?.primarySource || ""}}
            </p>
            <p class="font-12 font-w-500 mb-10">
              <span class="font-12 l-height-24 font-w-600 base-color me-2"
                >Primary Source Referrer:</span
              >
              {{memberDetails?.audienceSource?.primarySourceReferrer || ""}}
            </p>
            <p class="font-12 font-w-500 mb-10">
              <span class="font-12 l-height-24 font-w-600 base-color me-2"
                >Primary Source Term:</span
              >
              {{memberDetails?.audienceSource?.primarySourceTerm || ""}}
            </p>
            <p class="font-12 font-w-500 mb-10">
              <span class="font-12 l-height-24 font-w-600 base-color me-2"
                >Primary Source First Page:</span
              >{{memberDetails?.audienceSource?.primarySourceFirstPage || ""}}
            </p>
            <p class="font-12 font-w-500 mb-10">
              <span class="font-12 l-height-24 font-w-600 base-color me-2"
                >Prime Source Campaign:</span
              >{{memberDetails?.audienceSource?.primarySourceCampaign || ""}}
            </p>
          </div>
        </div>
        <div class="col-xxl-5 col-xl-12">
          <memberDetailsActivityFeeds />
        </div>
      </div>
    </div>
  </div>
</div>
