<div
  class="px-20 pt-20 pb-10 radius-10 bg-white box-shadow-5 mb-16 position-relative"
>
  <h4 class="font-18 font-w-600 mb-10">Activity Feeds</h4>
  <AppLoader v-if="isLoading" :type="'pulsing'"></AppLoader>
  <div class="d-flex flex-wrap flex-sm-nowrap gap-3 pb-24">
    <dropdownlist
      :class="'w-100-vsm min-w-100-vsm max-w-100-vsm'"
      :style="{ minWidth: '180px' , maxWidth: '180px' , zIndex: '0' }"
      :value="status"
      :data-items="stateList"
      :text-field="'status'"
      :data-item-key="'id'"
      :filter="filterValue"
      @filterchange="filterStateList"
      :filterable="true"
      @change="handleState"
    >
    </dropdownlist>
    <div class="d-flex flex-wrap flex-sm-nowrap gap-2 align-items-center ms-auto w-100--180 w-100-vsm">
      <label for="" class="mb-0 d-block w-100-vsm">Date Range</label>
      <AppDateRangePicker
        :dateRangeProps="[this.startDate,this.endDate]"
        @dateSelected="handleDateSelected"
        @error="handleError"
        :disableFutureDates="true"
        :class="'w-100-vsm max-w-100-vsm'"
        :style="{ maxWidth: 'calc(100% - 84px)', width: 'calc(100% - 84px)' }"
      >
      </AppDateRangePicker>
    </div>
  </div>
  <div class="border-top-normal-1 pt-24">
    <div class="">
      <div v-if="activityFeedItems?.length > 0">
        <listView
          :data-items="activityFeedItems"
          :item="'myItem'"
          :style="{ width: '100%', height: activityFeedItems?.length > 5 ? '430px' : ''}"
          :class="'member-details-activity-feeds border-0 pb-10'"
          @scroll="scrollHandler"
        >
          <template v-slot:myItem="{ props }">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h4>{{props.dataItem.activityTitle}}</h4>
                <p>{{getDateLocal(props.dataItem.activityTime)}}</p>
              </div>
              <span
                v-if="[3,4,5,6,7,8,9].includes(props?.dataItem?.activityType)"
                @click="onClickActivityDetails(props.dataItem)"
                class="cursor-pointer"
              >
                <svg
                  width="16"
                  height="10"
                  viewBox="0 0 16 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.25909 6.60214C1.94254 3.32689 4.79437 1 8.00002 1C11.2057 1 14.0574 3.32688 14.7409 6.60215C14.7974 6.87246 15.0622 7.04587 15.3325 6.98946C15.6029 6.93304 15.7763 6.66817 15.7199 6.39785C14.9425 2.67312 11.6934 0 8.00002 0C4.3066 0 1.05742 2.67311 0.280175 6.39786C0.223767 6.66818 0.397177 6.93305 0.667497 6.98946C0.937817 7.04587 1.20268 6.87246 1.25909 6.60214ZM7.98953 3C9.92253 3 11.4895 4.567 11.4895 6.5C11.4895 8.43299 9.92253 10 7.98953 10C6.05653 10 4.48953 8.43299 4.48953 6.5C4.48953 4.567 6.05653 3 7.98953 3Z"
                    fill="#212121"
                  />
                </svg>
              </span>
            </div>
          </template>
        </listView>
        <activityDetails
          v-if="isShowActivityDetailsLog"
          :activityDetailsDataItem="activityDetailsDataItem"
          @closeActivityDetailsModal="closeActivityDetailsModal"
        >
        </activityDetails>
      </div>
      <div v-else class="empty-status">
        <p class="text-center font-13 mb-0">No activity.</p>
      </div>
    </div>
  </div>
</div>
