import { DELETE, GET, PUT } from "@/service";

export const getActivityListService = (payload: any) => {
    return GET(`api/v1/audience/audience-activity`, {
      params: payload,
    });
  };

export const getActivityDetailsService = (payload: any) => {
    return GET(`api/v1/Audience/audience-activity-details`, {
      params: payload,
    });
  };
  
  export const getAudienceProfileService = (payload: any) => {
    return GET(`api/v1/audience/profile/get-audience-profile`, {
      params: payload,
    });
  };
  
  export const getAudienceListsService = (payload: any) => {
    return GET(`api/v1/audience/profile/get-audience-lists`, {
      params: payload,
    });
  };

  export const deleteAudienceListService = (payload: any) => {
    return DELETE(`api/v1/audience/profile/remove-from-list`, {
      data: payload,
    });
  };
  
  export const getAudienceSegmentsService = (payload: any) => {
    return GET(`api/v1/audience/profile/get-audience-segments`, {
      params: payload,
    });
  };
  export const getAudienceEditProfileService = (payload: any) => {
    return GET(`api/v1/audience/profile/get-audience-edit-profile`, {
      params: payload,
    });
  };

  export const memberProfileUpdate = (payload: any) => {
    return PUT(`api/v1/audience/profile/member-profile-update`, payload);
  };
  