<template src="./memberDetailsActivityFeeds.html"></template>
<style lang="scss">
@import "./memberDetailsActivityFeeds.scss";
</style>

<script>
import AppDateRangePicker from "@/components/common/AppDateRangePicker/AppDateRangePicker.vue";
import moment from "moment";
import { ListView, ListViewHeader } from "@progress/kendo-vue-listview";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { getActivityListService } from "@/services/clubDetails/profileDetails.service";
import activityDetails from "./activityDetails/activityDetails.vue";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import { filterBy } from "@progress/kendo-data-query";

export default {
  components: {
    AppLoader,
    AppDateRangePicker,
    listView: ListView,
    listViewHeader: ListViewHeader,
    dropdownlist: DropDownList,
    activityDetails,
  },
  data() {
    return {
      clubId: this.$route.params.id,
      userId: this.$route.params.memberId,
      startDate: "",
      endDate: "",
      pagination: {
        total: 0,
        offset: 0,
        limit: 10,
      },
      activityFeedItems: [],
      isLoading: false,
      stateList: [
        { id: 0, status: "All" },
        { id: 1, status: "Member Registration" },
        { id: 2, status: "Active on Site" },
        { id: 3, status: "Added to Cart" },
        { id: 4, status: "Abandoned Cart" },
        { id: 5, status: "Abandoned Cart Converted" },
        { id: 6, status: "Checkout Started" },
        { id: 7, status: "Order Placed" },
        { id: 8, status: "Cancelled Order" },
        { id: 9, status: "Fulfilled Order" },
        { id: 10, status: "Viewed Product" },
        { id: 11, status: "Form Submitted" },
        { id: 12, status: "Bounced Email" },
        { id: 13, status: "Received Email" },
        { id: 14, status: "Opened Email" },
        { id: 15, status: "Clicked Email" },
        { id: 16, status: "Clicked Email to Unsubscribe" },
      ],
      // 3-9
      stateListTemp: [],
      status: { id: 0, status: "All" },
      isShowActivityDetailsLog: false,
      activityDetailsDataItem: {},
      filterValue: "",
      currentTimeZone: null,
    };
  },
  computed: {},

  watch: {},
  mounted() {
    this.currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.getActivityList({
      clubId: this.clubId,
      clubUserId: this.userId,
      activityType: this.status?.id,
      fromDate: this.startDate,
      toDate: this.endDate,
      limit: this.pagination.limit,
      offset: this.pagination.offset,
      timezone: this.currentTimeZone
    });
    this.updateStateListTemp();
  },
  created() {
    this.setDates();
  },
  methods: {
    setDates() {
      const today = new Date();
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(today.getDate() - 7);

      this.endDate = today.toISOString();
      this.startDate = sevenDaysAgo.toISOString();
    },
    onClickActivityDetails(dataItem) {
      this.isShowActivityDetailsLog = true;
      this.activityDetailsDataItem = dataItem;
    },
    closeActivityDetailsModal(dataItem) {
      this.isShowActivityDetailsLog = false;
      this.activityDetailsDataItem = {};
    },
    updateStateListTemp() {
      this.stateListTemp = [...this.stateList];
    },
    filterStateList(event) {
      this.filterValue = event.filter.value;
      const dataMain = this.stateListTemp?.slice();
      event.filter.value = event.filter.value.trim();
      this.stateList = filterBy(dataMain, event.filter);
    },
    handleState(event) {
      this.status = event.value;
      this.activityFeedItems = [];
      this.pagination.offset = 0;
      this.getActivityList({
        clubId: this.clubId,
        clubUserId: this.userId,
        activityType: this.status?.id,
        fromDate: this.startDate,
        toDate: this.endDate,
        limit: this.pagination.limit,
        offset: this.pagination.offset,
        timezone: this.currentTimeZone
      });
      this.filterValue = "";
      this.stateList = this.stateListTemp;
    },
    handleDateSelected({ start, end }) {
      this.startDate = start != null ? new Date(start) : null;
      this.endDate = end != null ? new Date(end) : null;
      if (!this.startDate && !this.endDate) {
        this.setDates();
      }
      this.activityFeedItems = [];
      this.pagination.offset = 0;
      this.getActivityList({
        clubId: this.clubId,
        clubUserId: this.userId,
        activityType: this.status?.id,
        fromDate: this.startDate,
        toDate: this.endDate,
        limit: this.pagination.limit,
        offset: this.pagination.offset,
        timezone: this.currentTimeZone
      });
    },
    getActivityList(payload) {
      this.isLoading = true;
      getActivityListService(payload)
        .then((response) => {
          this.isLoading = false;
          const newItems = response?.data?.data?.items || [];
          const existingItemIds = this.activityFeedItems.map((item) => item.id);
          const uniqueNewItems = newItems.filter(
            (item) => !existingItemIds.includes(item.id)
          );
          this.activityFeedItems.push(...uniqueNewItems);
          this.pagination.limit = response?.data?.data?.limit;
          this.pagination.total = response?.data?.data?.size;
          this.pagination.offset = response?.data?.data?.offset;
        })
        .catch((error) => {
          this.isLoading = false;
          console.error("Error fetching activity list:", error);
        });
    },
    scrollHandler(e) {
      if (this.pagination.total < this.pagination.offset) return;
      if (
        e.target.scrollTop + 10 >=
        e.target.scrollHeight - e.target.clientHeight
      ) {
        this.pagination.offset += 10;

        this.getActivityList({
          clubId: this.clubId,
          clubUserId: this.userId,
          activityType: this.status?.id,
          fromDate: this.startDate,
          toDate: this.endDate,
          limit: this.pagination.limit,
          offset: this.pagination.offset,
          timezone: this.currentTimeZone
        });
      }
    },
    getDateLocal(date) {
      if (date != null) {
        return moment(date).format("MMM D YYYY hh:mm A");
      } else {
        return "-";
      }
    },
  },
};
</script>
