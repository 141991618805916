<template src="./addToListModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "@/components/common/AppModal/AppModal";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { DropDownTree } from "@progress/kendo-dropdowntree-vue-wrapper";
import { Checkbox } from "@progress/kendo-vue-inputs";
import {
  getListsService,
  saveToListService,
} from "@/services/clubDetails/member.service";

export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    checkbox: Checkbox,
    modal,
    dropdownlist: DropDownList,
    dropdowntree: DropDownTree,
  },
  data: function () {
    return {
      clubId: this.$route.params.id,
      userId: this.$route.params.memberId,
      entityType: +this.$route.query.type,
      digitalPassCheckBoxStatus: false,
      digitalPassListIds: [],
      localDataSourceForDigitalPass: [],
      voucherCheckBoxStatus: false,
      voucherCampaignListIds: [],
      localDataSourceForVoucher: [],
      emailCampaignCheckBoxStatus: false,
      localDataSourceForEmailCampaign: [],
      emailCampaignListIds: [],
      isLoader: false,
      memberListId: null
    };
  },
  mounted() {
    this.getDigitalPassList();
    this.getVouchersList();
    this.getEmailCampaignList();
  },
  computed: {
    submitButtonDisabled() {
      if (
        (!this.digitalPassCheckBoxStatus) &&
        (!this.voucherCheckBoxStatus) &&
        (!this.emailCampaignCheckBoxStatus)
      ) {
        return true
      }
      return false
    },
  },
  methods: {
    async getDigitalPassList() {
      this.isLoader = true;
      const payload = {
        ClubId: this.clubId,
        ClubUserId: this.userId,
        entityType: 1,
      };
      try {
        const result = await getListsService(payload);
        this.isLoader = false
        if (result?.data?.data?.length > 0) {
          result?.data?.data?.forEach((list) => {
            this.localDataSourceForDigitalPass.push({
              text: list.name,
              value: list.listId,
            });
          });
        }
      } catch (error) {
        this.isLoader = false;
      }
    },
    async getVouchersList() {
      this.isLoader = true;
      const payload = {
        ClubId: this.clubId,
        ClubUserId: this.userId,
        entityType: 3,
      };
      try {
        const result = await getListsService(payload);
        this.isLoader = false;
        if (result?.data?.data?.length > 0) {
          result?.data?.data?.forEach((list) => {
            this.localDataSourceForVoucher.push({
              text: list.name,
              value: list.listId,
            });
          });
        }
      } catch (error) {
        this.isLoader = false;
      }
    },
    async getEmailCampaignList() {
      this.isLoader = true;
      const payload = {
        ClubId: this.clubId,
        ClubUserId: this.userId,
        entityType: 2,
      };
      try {
        const result = await getListsService(payload);
        this.isLoader = false;
        if (result?.data?.data?.length > 0) {
          result?.data?.data?.forEach((list) => {
            this.localDataSourceForEmailCampaign.push({
              text: list.name,
              value: list.listId,
            });
          });
        }
      } catch (error) {
        this.isLoader = false;
      }
    },
    handleDigitalPassCheckBoxStatus(event) {
      this.digitalPassCheckBoxStatus = event.value;
    },
    digitalPassListonChange(event) {
      this.digitalPassListIds = event.sender._values;
    },
    handleVoucherCheckBoxStatus(event) {
      this.voucherCheckBoxStatus = event.value;
    },
    voucherListonChange(event) {
      this.voucherCampaignListIds = event.sender._values;
    },
    emailCampaignListonChange(event) {
      this.emailCampaignListIds = event.sender._values;
    },
    handleEmailCampaignCheckBoxStatus(event) {
      this.emailCampaignCheckBoxStatus = event.value;
    },
    modalHandler() {
      this.$emit("closeAddToListModal");
    },
    saveToListHandler() {
      this.isLoader = true;

      const payload = {
        clubId: this.clubId,
        clubUserId: this.userId,
      };

      if (this.submitButtonDisabled) {
        this.isLoader = false;
        console.log("No lists or empty lists selected for saving.");
        this.$store.commit("SHOW_NOTIFICATION", {
          message: "You must choose at least one module to add to the list!",
          type: "warning",
        });
        return;
      }

      if (this.digitalPassCheckBoxStatus) {
        payload.assignToDigitalPassLists = true;
        payload.digitalPassListIds = this.digitalPassListIds;
      }

      if (this.voucherCheckBoxStatus) {
        payload.assignToVoucherCampaignLists = true;
        payload.voucherCampaignListIds = this.voucherCampaignListIds;
      }
      if (this.emailCampaignCheckBoxStatus) {
        payload.assignToEmailCampaignLists = true;
        payload.emailCampaignListIds = this.emailCampaignListIds;
      }
      saveToListService(payload)
        .then(() => {
          this.isLoader = false;
          this.$emit("reFetchData");
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "Successfully added to the list!",
            type: "success",
          });
        })
        .catch(() => {
          this.isLoader = false;
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "Something went wrong. Please try again later.",
            type: "error",
          });
        });
    },
  },
};
</script>
