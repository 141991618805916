<Grid :class="'members-details-segment-table table-design-2 max-height-589 mb-20 th-td-checkbox-style-2'" ref="grid"
    :data-items="segmentList" :columns="columns" @datastatechange="dataStateChange"
    @headerselectionchange="onHeaderSelectionChange" :sortable="true" :sort="sort" @sortchange="sortChangeHandler">
    <template v-slot:nameCell="{ props }">
        <td>
            <span class="font-14 font-w-500 color-blue">{{props.dataItem.name}}</span>
        </td>
    </template>
    <template v-slot:dateCell="{ props }">
        <td>
            <span>{{getDateLocal(props.dataItem.updatedAt)}}</span>
        </td>
    </template>
</Grid>