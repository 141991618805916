<AppDrawer
  ref="appDrawer"
  :is-open="isDrawerOpen"
  :speed="500"
  :outsideClickCloseDisabled=true
  @close="closeDrawer"
>
  <app-loader v-if="this.isLoading || loader"></app-loader>
  <div class="bg-white h-100 d-flex flex-column">
    <div class="px-20 py-14 border-bottom-normal-1">
      <div class="d-flex flex-wrap justify-content-between align-items-center">
        <h4 class="font-18 font-w-600 mb-0">Edit Profile</h4>
        <kbutton
          @click="closeDrawer"
          :class="'base-color bg-white border-0 p-0'"
          :fill-mode="'outline'"
          :theme-color="'primary'"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18M6 6L18 18"
              stroke="#101828"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </kbutton>
      </div>
    </div>
    <div v-if="!isLoading" class="overflow-y-auto max-height-100--128">
      <div class="px-20">
        <div class="border-bottom-normal-1 py-24 mb-24">
          <label class="font-16 l-height-17 font-w-600 mb-12"
            >Change Subscription Status</label
          >
          <dropdownlist
            :class="'h-44'"
            :style="{ width: '100%', height: '44px' }"
            :data-items="statusList"
            :text-field="'subscriberStatus'"
            :data-item-key="'id'"
            :value="this.basicDetails.subscriberObj"
            @change="handleValueChange($event, 'subscriberObj')"
          >
          </dropdownlist>
        </div>
      </div>
      <div class="px-20">
        <h6
          class="font-16 l-height-17 font-w-600 mb-12"
        >
          Basic Details
        </h6>
        <div class="">
          <div class="row">
            <div class="col-md-6 col-12 pe-2">
              <div class="k-form-field text-start">
                <label
                  class="font-14 l-height-20 font-w-500 text-color-3 mb-10 d-block"
                  >First Name <span class="red">*</span></label
                >
                <k-input
                  :class="'h-44 mb-16'"
                  @keyup="this.basicDetails.firstNameError=''"
                  v-model="this.basicDetails.firstName"
                  :placeholder="'Input First Name'"
                />
                <div class="error-msg">
                  {{ this.basicDetails.firstNameError }}
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12 ps-2">
              <div class="k-form-field text-start">
                <label
                  class="font-14 l-height-20 font-w-500 text-color-3 mb-10 d-block"
                  >Last Name <span class="red">*</span></label
                >
                <k-input
                  :class="'h-44 mb-16'"
                  @keyup="this.basicDetails.lastNameError=''"
                  v-model="this.basicDetails.lastName"
                  :placeholder="'Input Last Name'"
                />
                <div class="error-msg">
                  {{ this.basicDetails.lastNameError }}
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12 pe-2">
              <div class="k-form-field text-start">
                <label
                  class="font-14 l-height-20 font-w-500 text-color-3 mb-10 d-block"
                  >Email Address <span class="red">*</span></label
                >
                <k-input
                  :class="'h-44 mb-16'"
                  @keyup="clearEmailError"
                  v-model="this.basicDetails.email"
                  :disabled="true"
                  :placeholder="'user@domain.com'"
                />
              </div>
            </div>
            <div class="col-md-6 col-12 ps-2">
              <div class="k-form-field text-start">
                <label
                  class="font-14 l-height-20 font-w-500 text-color-3 mb-10 d-block"
                  >Phone Number</label
                >
                <k-input
                  type="text"
                  :class="'h-44 mb-16'"
                  v-model="basicDetails.mobile"
                  :placeholder="'+61 XXX XXX XXXX'"
                  @input="formatMobileInput"
                  @keydown="preventInvalidKey"
                />
              </div>
            </div>
            <div class="col-md-6 col-12 pe-2">
              <div class="k-form-field text-start">
                <label
                  class="font-14 l-height-20 font-w-500 text-color-3 mb-10 d-block"
                  >Gender</label
                >
                <dropdownlist
                  :class="'h-44 mb-16'"
                  :data-items="this.basicDetails.genderList"
                  :text-field="'name'"
                  :data-item-key="'id'"
                  :value="basicDetails.genderValueObj"
                  @change="handleValueChange($event, 'genderValueObj')"
                >
                </dropdownlist>
              </div>
            </div>
            <div class="col-md-6 col-12 ps-2">
              <div class="k-form-field text-start">
                <label
                  class="font-14 l-height-20 font-w-500 text-color-3 mb-10 d-block"
                  >Timezones</label
                >
                <dropdownlist
                  :data-items="timeZonesList"
                  :text-field="'name'"
                  :data-item-key="'id'"
                  :class="'h-44 mb-16'"
                  :filterable="true"
                  @filterchange="filterChange"
                  :value="basicDetails.timezoneObj"
                  @change="handleValueChange($event, 'timezoneObj')"
                >
                </dropdownlist>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="customData.length > 0" class="px-20">
        <div class="border-top-normal-1 mt-8 mb-24 pt-24">
          <h6
            class="font-16 l-height-17 font-w-600 mb-12"
          >
            Custom Fields
          </h6>
          <div class="">
            <div
              v-for="(item,index) in customData"
              :key="item.id"
              class="mb-16"
            >
              <label
                v-if="item.isVisible"
                class="font-14 l-height-20 font-w-500 text-color-3 mb-10 d-block"
                >{{ item.label }}
                <span v-if="item?.isRequired" class="red">*</span>
              </label>
              <k-input
                v-if="[1,3].includes(item.fieldType) && item.isVisible"
                @keyup="item.error = ''"
                :type="item.fieldType == 1 ? 'text' : 'number'"
                v-model="item.value"
                :placeholder="item.label"
                :class="'mb-0 h-44'"
              />
              <dropdownlist
                v-if="[2].includes(item.fieldType) && item.isVisible"
                :data-items="item.audienceOptions"
                :text-field="'value'"
                :data-item-key="'text'"
                :class="'mb-0 h-44'"
                :value="item.valueObj"
                @change="handleCustomValueChange($event, item)"
              >
              </dropdownlist>            
              <datePicker
                v-if="[4].includes(item.fieldType) &&  item.isVisible"
                :format="'dd-MMM-yyyy'"
                :value="item.value ? new Date(item.value) : null"
                @change="updateExpiryDate($event,item)"
                :class="'button-bg-transparent button-border-none mb-0 h-44'"
              >
              </datePicker>
              <div class="error-msg ms-1 mt-1">{{ item?.error }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="bg-white box-shadow-3 d-flex justify-content-end mt-auto py-16 px-20"
    >
      <div class="me-1">
        <kbutton
          @click="this.closeDrawer"
          :class="'border-normal-1 text-color h-44'"
          :theme-color="'primary'"
          :fill-mode="'outline'"
          >Cancel</kbutton
        >
      </div>
      <div class="ms-1">
        <kbutton
          @click="this.updateMemberModalHandler"
          :theme-color="'primary'"
          :class="'h-44'"
        >
          Update
        </kbutton>
      </div>
    </div>
  </div>
</AppDrawer>
