<modal :isLoading="isLoading" :type="'pulsing'" :title="'Activity Details'" :handler="true" :cssClass="'modal-md-3 footer-p-0'"
    @modalHandler="modalHandler">
    <template v-slot:content>
        <div class="pb-24">
            <label>Activity Data:</label>
            <div class="p-10 radius-10 bg-gray-4">
                <vue-json-pretty v-if="detailsData && detailsData?.JsonValue" :data="JSON.parse(detailsData?.JsonValue)" :showLineNumber="true" :showIcon="true" :virtual="true" />
            </div>
        </div>
    </template>
</modal>