<modal
  :isLoading="isLoader"
  :type="'pulsing'"
  :title="'Add to List'"
  :handler="true"
  :cssClass="'modal-md-3'"
  @modalHandler="modalHandler"
>
  <template v-slot:content>
    <div class="">
      <div class="border-normal-1 radius-8 mb-16">
        <div class="text-start">
          <div class="p-12 bg-gray-4 radius-8">
            <label class="m-0 font-13 font-w-500 text-color-2"
              >Include Imported Members In</label
            >
          </div>
          <div class="px-15">
            <div class="row k-input-mb-0 border-top-normal-1 py-16">
              <div class="col-md-6 col-sm-12">
                <checkbox
                  :value="this.digitalPassCheckBoxStatus"
                  @change="this.handleDigitalPassCheckBoxStatus"
                >
                  <label :class="'font-14 font-w-600 mb-0 ms-2'"
                    >Digital Pass</label
                  >
                </checkbox>
                <label
                  class="font-13 font-w-500 text-color-2 mb-0 ml-22 d-block mt-1"
                  v-if="this.digitalPassCheckBoxStatus"
                  >Assign to Digital Pass list</label
                >
              </div>
              <div
                class="col-md-6 col-sm-12"
                v-if="this.digitalPassCheckBoxStatus"
              >
                <div class="dropdown-tree-wrap">
                  <dropdowntree
                    @change="digitalPassListonChange"
                    :data-source="this.localDataSourceForDigitalPass"
                    :checkboxes="true"
                    :autoClose="false"
                    :placeholder="'Select List'"
                    style="width: 100%"
                  >
                  </dropdowntree>
                  <span class="dropdown-item-count"
                    >{{digitalPassListIds.length}}</span
                  >
                </div>
              </div>
            </div>
            <div class="row k-input-mb-0 border-top-normal-1 py-16">
              <div class="col-md-6 col-sm-12">
                <checkbox
                  :value="this.voucherCheckBoxStatus"
                  @change="this.handleVoucherCheckBoxStatus"
                >
                  <label :class="'font-14 font-w-600 mb-0 ms-2'">Voucher</label>
                </checkbox>
                <label
                  class="font-13 font-w-500 text-color-2 mb-0 ml-22 d-block mt-1"
                  v-if="this.voucherCheckBoxStatus"
                  >Assign to Voucher list</label
                >
              </div>
              <div class="col-md-6 col-sm-12" v-if="this.voucherCheckBoxStatus">
                <div class="dropdown-tree-wrap">
                  <dropdowntree
                    @change="voucherListonChange"
                    :data-source="this.localDataSourceForVoucher"
                    :checkboxes="true"
                    :autoClose="false"
                    :placeholder="'Select List'"
                    style="width: 100%"
                  >
                  </dropdowntree>
                  <span class="dropdown-item-count"
                    >{{voucherCampaignListIds.length}}</span
                  >
                </div>
              </div>
            </div>

            <div class="row k-input-mb-0 border-top-normal-1 py-16">
              <div class="col-md-6 col-sm-12">
                <checkbox
                  :value="emailCampaignCheckBoxStatus"
                  @change="handleEmailCampaignCheckBoxStatus"
                >
                  <label :class="'font-14 font-w-600 mb-0 ms-2'"
                    >Email Campaign</label
                  >
                </checkbox>
                <label
                  class="font-13 font-w-500 text-color-2 mb-0 ml-22 d-block mt-1"
                  v-if="emailCampaignCheckBoxStatus"
                  >Assign to Campaign List</label
                >
              </div>
              <div
                class="col-md-6 col-sm-12"
                v-if="emailCampaignCheckBoxStatus"
              >
                <div class="dropdown-tree-wrap">
                  <dropdowntree
                    @change="emailCampaignListonChange"
                    :data-source="localDataSourceForEmailCampaign"
                    :noDataTemplate="'No lists found.'"
                    :checkboxes="true"
                    :autoClose="false"
                    :placeholder="'Select List'"
                    style="width: 100%"
                  >
                  </dropdowntree>
                  <span class="dropdown-item-count"
                    >{{emailCampaignListIds.length}}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-slot:footer :class="'justify-content-end modal-footer'">
    <div class="d-flex justify-content-end">
      <div class="me-1">
        <kbutton
          @click="modalHandler"
          :class="'w-100 border-normal-1 base-color'"
          :fill-mode="'outline'"
          :theme-color="'primary'"
          >Cancel</kbutton
        >
      </div>
      <div class="ms-1">
        <kbutton
          :disabled="submitButtonDisabled"
          :theme-color="'primary'"
          :class="'w-100'"
          @click="saveToListHandler"
          >Save</kbutton
        >
      </div>
    </div>
  </template>
</modal>
