<template src="./memberDetailsEdit.html"></template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import { Input } from "@progress/kendo-vue-inputs";
import { DropDownTree } from "@progress/kendo-dropdowntree-vue-wrapper";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import AppDrawer from "@/components/common/AppDrawer/AppDrawer.vue";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { filterBy } from "@progress/kendo-data-query";
import {
  getAudienceEditProfileService,
  memberProfileUpdate,
} from "@/services/clubDetails/profileDetails.service";

export default {
  name: "memberDetailsEdit",
  components: {
    kbutton: Button,
    "k-input": Input,
    "app-loader": AppLoader,
    datePicker: DatePicker,
    dropdowntree: DropDownTree,
    AppDrawer,
    dropdownlist: DropDownList,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    audienceId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      loader : false,
      statusList: [
        { subscriberStatus: "Subscribed", id: 1 },
        { subscriberStatus: "Unsubscribed", id: 2 },
        { subscriberStatus: "Non-subscribed", id: 0 },
        { subscriberStatus: "Cleaned", id: 3 },
      ],

      clubId: this.$route.params.id,
      userId: this.$route.params.memberId,
      basicDetails: {
        firstName: "",
        firstNameError: "",
        lastName: "",
        lastNameError: "",
        email: "",
        emailError: "",
        mobile: "",
        mobileError:"",
        genderValueObj: { id: 1, name: "Male" },
        genderItems: [
          { id: 1, name: "Male" },
          { id: 2, name: "Female" },
          { id: 3, name: "Unspecified" },
        ],
        timezoneObj: { name: "Africa/Abidjan", id: 1 },
        subscriberObj: { subscriberStatus: "Subscribed", id: 1 },
        customFieldDetails: [],
      },
      notifyMessage: "",
      notifyType: "",
      isNotify: false,
      searchData: "",
      isLoading: false,
      selectedOption: null,
      options: [
        { value: "option1", text: "Option 1" },
        { value: "option2", text: "Option 2" },
        { value: "option3", text: "Option 3" },
      ],
      customData: [],
    };
  },
  watch: {
    timeZonesList(newVal) {
      const timeZone = this.club.timeZoneId;
      this.basicDetails.timeZoneValue = this.timeZonesList.find((item) => {
        return item.id === timeZone;
      });
    },
  },
  mounted() {
    this.$store.dispatch("getTimeZonesList", 999);
    if (this.type) {
      this.getAudienceEditProfileData({
        ClubId: this.clubId,
        ClubUserId: this.userId,
        IsAudience: true,
        AudienceId: this.audienceId,
        EntityType: this.type,
      });
    } else {
      this.getAudienceEditProfileData({
        ClubId: this.clubId,
        ClubUserId: this.userId,
      });
    }
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  computed: {
    addMemberErrors() {
      return this.$store.state.members.addMemberErrors;
    },
    timeZonesList() {
      return this.$store.state.commonEmailCampaign.timeZoneList;
    },
    club() {
      return this.$store.state.club.club;
    },
  },

  methods: {
    getAudienceEditProfileData(payload) {
      this.isLoading = true;
      getAudienceEditProfileService(payload)
        .then((response) => {
          this.isLoading = false;
          const basicDetailsList = response?.data?.data?.audienceBasicDetails;
          const customDataList =
            response?.data?.data?.audienceCustomFieldDetails;
          this.basicDetails = basicDetailsList;
          this.customData = customDataList;
        })
        .catch((error) => {
          this.isLoading = false;
          console.error("Error fetching tenement list:", error);
        });
    },
    closeDrawer() {
      this.$emit("closeDrawer");
    },
    updateCloseDrawer() {
      this.$emit("updateCloseDrawer");
    },
    handleValueChange($event, field) {
      $event.event.stopPropagation();
      this.basicDetails[field] = $event.value;
    },
    handleCustomValueChange($event, item) {
      $event.event.stopPropagation();
      item.valueObj = $event.value;
      item.value = $event?.value?.value;
      item.error = "";
    },
    formatMobileInput(event) {
      const input = event.target.value;
      const formattedInput = input.replace(/[^+\d\s]/g, '');
      this.basicDetails.mobile = formattedInput;
    },
    preventInvalidKey(event) {
      if (event.keyCode === 69 || (event.key.length === 1 && !/[+\d\s]/.test(event.key))) {
        event.preventDefault();
      }
    },
    filterChange(event) {
      this.searchData = "";
      let data = this.$store.state.commonEmailCampaign.timeZoneTempList;
      this.searchData = filterBy(data, event.filter);
      this.$store.commit("SET_TIME_ZONE_LIST", this.searchData);
    },
    filterStateChange(event) {
      this.searchData = "";
      let data = this.$store.state.commonEmailCampaign.timeZoneTempList;
      this.searchData = filterBy(data, event.filter);
      this.$store.commit("SET_TIME_ZONE_LIST", this.searchData);
    },
    timeZoneInit() {
      let timeZone = this.club.timeZoneId;
      if (timeZone != 999) {
        return this.timeZonesList.find((item) => item.id === timeZone);
      } else {
        return this.timeZonesList.find(
          (item) =>
            item.name === Intl.DateTimeFormat().resolvedOptions().timeZone
        );
      }
    },
    updateExpiryDate($event, item) {
      $event.event.stopPropagation();
      item.value = $event?.value;
      item.error = "";
    },
    clearEmailError() {
      if (this.addMemberErrors.Email) {
        this.addMemberErrors.Email[0] = "";
      }
      this.basicDetails.emailError = "";
    },
    updateMemberModalHandler() {
      if (this.dataValidation()) {

        this.loader = true;
        const basePayload = {
          clubId: this.clubId,
          firstName: this.basicDetails.firstName,
          lastName: this.basicDetails.lastName,
          timezone: this.basicDetails.timezoneObj?.name,
          email: this.basicDetails.email,
          mobile: this.basicDetails.mobile,
          genderId: this.basicDetails.genderValueObj?.id,
          subscriberStatus: this.basicDetails.subscriberObj?.id,
          customFieldDetails: this.customData,
          email: this.basicDetails.email,
        };
        const payload = this.type
          ? {
              ...basePayload,
              IsAudience: true,
              AudienceId: this.audienceId,
              EntityType: this.type,
            }
          : basePayload;

        memberProfileUpdate(payload)
          .then(() => {
            this.loader = false;
            this.$store.commit("SHOW_NOTIFICATION", {
              message:
                "Your Audience profile information is successfully updated.",
              type: "success",
            });
            this.updateCloseDrawer();
          })
          .catch(() => {
            this.loader = false;
            this.$store.commit("SHOW_NOTIFICATION", {
              message: "Something went wrong. Please try again later.",
              type: "error",
            });
          });
      }
    },
    handleClickOutside(event) {
      const modal = this.$refs.modal; // Use ref to access the modal element
      if (modal && !modal.contains(event.target)) {
        this.closeDrawer();
      }
    },
    dataValidation() {
      
      let hasError = true;
      function onlyNumbers(str) {
        return /^[+\d\s]*$/.test(str);
      }
      

      function onlySpaces(str) {
        return /^\s*$/.test(str);
      }

      if (!this.basicDetails.firstName) {
        this.basicDetails.firstNameError = "First Name is required.";
        hasError = false;
      }
      

      if (!this.basicDetails.lastName) {
        this.basicDetails.lastNameError = "Last Name is required.";
        hasError = false;
      }
      
      if (onlySpaces(this.basicDetails.firstName)) {
        this.basicDetails.firstNameError = "First Name is required.";
        hasError = false;
      }

      if (onlySpaces(this.basicDetails.lastName)) {
        this.basicDetails.lastNameError = "Last Name is required.";
        hasError = false;
      }
      
      if (this.basicDetails?.mobile?.length > 0 && !onlyNumbers(this.basicDetails.mobile)) {
        this.basicDetails.mobileError = "Invalid mobile number. Only digits are allowed.";
        hasError = false;
      }
      if (this.basicDetails.firstName.length > 25) {
        this.basicDetails.firstNameError = "Enter less than 25 characters.";
        hasError = false;
      }
      if (this.basicDetails.lastName.length > 25) {
        this.basicDetails.lastNameError = "Enter less than 25 characters.";
        hasError = false;
      }
      if(this.customData.length > 0) {
        this.customData.forEach((item) => {
          
          if (item?.isRequired && !item?.value && item?.isVisible) {
            item.error = "This field is required.";
            hasError = false;
          }
        });
      }
      if ( hasError == false) {
        this.$store.commit("SHOW_NOTIFICATION", {
            message: "Please Check All Validations.",
            type: "warning",
          });
      }
      

      return hasError;
    },
  },
};
</script>

<style>
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;*/
}
</style>
